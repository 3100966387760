/* eslint-disable @typescript-eslint/no-unused-vars */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type * as Types from '@wirechunk/lib/api.ts';

export type OrganizationSiteContextQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['String']['input'];
}>;

export type OrganizationSiteContextQuery = {
  organization: {
    __typename: 'Organization';
    id: string;
    site?: {
      __typename: 'Site';
      id: string;
      platformId: string;
      domain: string;
      name: string;
      orgId?: string | null;
      logoUrl?: string | null;
      logoIconUrl?: string | null;
      headEndCode?: string | null;
      stripePublishableKey?: string | null;
      mixpanelToken?: string | null;
      googleTagManagerId?: string | null;
      metaPixelId?: string | null;
      zendeskChatKey?: string | null;
      agentFirstName?: string | null;
      agentLastName?: string | null;
      agentPhotoUrl?: string | null;
      agencyPrimaryPhone?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressCity?: string | null;
      addressState?: string | null;
      addressZip?: string | null;
      googlePlaceId?: string | null;
    } | null;
  };
};

export const OrganizationSiteContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrganizationSiteContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'site' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SiteSettings' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteSettings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'platformId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orgId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoIconUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headEndCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripePublishableKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mixpanelToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleTagManagerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaPixelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zendeskChatKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentPhotoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyPrimaryPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressZip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationSiteContextQuery, OrganizationSiteContextQueryVariables>;
