import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MapComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useSiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import { GoogleMap } from '../GoogleMap/GoogleMap.tsx';

export const Map: FunctionComponent<MapComponent> = (props) => {
  const { googlePlaceId } = useSiteContext();

  let placeId: string | null | undefined;
  switch (props.focus?.type) {
    case 'CustomGooglePlace':
      placeId = props.focus.placeId;
      break;
    case 'SiteGooglePlace':
    case 'OrganizationPlace':
    default:
      placeId = googlePlaceId;
      break;
  }

  if (!placeId) {
    return null;
  }

  return <GoogleMap className={componentClassName(props)} googlePlaceId={placeId} />;
};
